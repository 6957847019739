.login_outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
.login_outer .login_content {
  flex: 2;
}
.login_outer .login_footer {
  color: white;
}
